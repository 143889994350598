import React from 'react';
import { BentoGrid, BentoGridItem } from './ui/bento-grid';
import { IconBulb, IconCpu, IconPalette, IconMicrophone, IconBook, IconCode, IconRocket, IconBrandGithub, IconWorld, IconCamera, IconLock, IconChartBar, IconDatabase, IconCloud, IconVirus } from '@tabler/icons-react';
import '../styles/ProjectsPage.scss';

interface Project {
  id: number;
  title: string;
  description: string;
  icon: React.ReactNode;
  className: string;
}

const tempProjects: Project[] = [
  { id: 1, title: "The Dawn of Innovation", description: "Explore the birth of groundbreaking ideas and inventions.", icon: <IconBulb />, className: "card-1" },
  { id: 2, title: "The Digital Revolution", description: "Dive into the transformative power of technology.", icon: <IconCpu />, className: "card-2" },
  { id: 3, title: "The Art of Design", description: "Discover the beauty of thoughtful and functional design.", icon: <IconPalette />, className: "card-3" },
  { id: 4, title: "The Power of Communication", description: "Understand the impact of effective communication in our lives.", icon: <IconMicrophone />, className: "card-4" },
  { id: 5, title: "The Pursuit of Knowledge", description: "Join the quest for understanding and enlightenment.", icon: <IconBook />, className: "card-5" },
  { id: 6, title: "The Joy of Creation", description: "Experience the thrill of bringing ideas to life.", icon: <IconCode />, className: "card-6" },
  { id: 7, title: "The Spirit of Adventure", description: "Embark on exciting journeys and thrilling discoveries.", icon: <IconRocket />, className: "card-7" },
  { id: 8, title: "The Future of AI", description: "Explore the cutting-edge advancements in artificial intelligence.", icon: <IconBrandGithub />, className: "card-8" },
  { id: 9, title: "Sustainable Technologies", description: "Discover innovations driving a more sustainable future.", icon: <IconWorld />, className: "card-9" },
  { id: 10, title: "The Art of Photography", description: "Capture moments and tell stories through the lens.", icon: <IconCamera />, className: "card-10" },
  { id: 11, title: "Cybersecurity Essentials", description: "Protect digital assets in an increasingly connected world.", icon: <IconLock />, className: "card-11" },
  { id: 12, title: "Data Visualization", description: "Transform complex data into meaningful visual insights.", icon: <IconChartBar />, className: "card-12" },
  { id: 13, title: "Big Data Analytics", description: "Harness the power of large-scale data processing.", icon: <IconDatabase />, className: "card-13" },
  { id: 14, title: "Cloud Computing", description: "Explore the scalable and flexible world of cloud services.", icon: <IconCloud />, className: "card-14" },
  { id: 15, title: "Bioinformatics", description: "Apply computational techniques to analyze biological data.", icon: <IconVirus />, className: "card-15" },
];

const ProjectsPage: React.FC = () => {
  return (
    <div className="projects-page">
      <h1>My Projects</h1>
      <div className="projects-grid custom-layout">
        {tempProjects.map((project) => (
          <BentoGridItem
            key={project.id}
            title={project.title}
            description={project.description}
            icon={<div className="project-icon">{project.icon}</div>}
            className={`project-card ${project.className}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectsPage;
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import myPhoto from '../assets/myPhoto.jpg';
import '../styles/ProfilePage.scss';
import { Sidebar, SidebarBody, SidebarLink } from '../components/ui/sidebar';
import { IconHome, IconNotes, IconRocket, IconMessageCircle2, IconCertificate } from '@tabler/icons-react';

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();

  const sidebarLinks = [
    { label: "Home", href: "/", icon: <IconHome size={24} /> },
    { label: "Posts", href: "/posts", icon: <IconNotes size={24} /> },
    { label: "Projects", href: "/projects", icon: <IconRocket size={24} /> },
    { label: "Feedback", href: "/feedback", icon: <IconMessageCircle2 size={24} /> },
    { label: "Certifications", href: "/certifications", icon: <IconCertificate size={24} /> },
  ];

  return (
    <div className="profile-page">
      <Sidebar>
        <SidebarBody className="sidebar-content">
          <div className="logo">A</div>
          <nav>
            {sidebarLinks.map((link) => (
              <SidebarLink key={link.href} link={link} />
            ))}
          </nav>
        </SidebarBody>
      </Sidebar>
      
      <div className="main-content">
        <header>
          <h1 className="myName">Abukar Abdullahi Abukar</h1>
          <p className="tagline">
            Hey, I'm Abukar. I'm a Full Stack Developer passionate about building innovative web and mobile applications.
          </p>
        </header>
        <div className="profile-stats">
          <div className="profile-image">
            <img src={myPhoto} alt="Abukar Abdulle" />
          </div>
          <div className="stats">
            <div><span className="icon">🏅</span> Alpha Sigma Lambda Honor Society Member</div>
            <div><span className="icon">🎓</span> Dean's Honor Roll</div>
            <div><span className="icon">📈</span> 3.80 GPA at Creighton University</div>
            <div><span className="icon">📚</span> 3.98 GPA at Regis University</div>
          </div>
        </div>
        <p className="bio">
          With expertise in React, Node.js, and Java, I'm passionate about creating efficient and scalable web and mobile applications. Additionally, I have a strong interest in cybersecurity and am committed to learning and applying best practices to build secure and resilient applications.
        </p>
        <div className="cta-buttons">
          <a href="https://www.linkedin.com/in/abukarshegow/" target="_blank" rel="noopener noreferrer">
            Follow Me on Linkedin
          </a>
          <a href="https://github.com/Abukarabukar" target="_blank" rel="noopener noreferrer">Check My Latest Projects</a>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
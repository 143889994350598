import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TicTacToe from './components/TicTacToe';
import LandingPage from './components/LandingPage';
import ProfilePage from './components/ProfilePage';
import ProjectsPage from './components/ProjectsPage';
// import ComingSoonPage from './components/ComingSoonPage';

import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/game" element={<TicTacToe />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        
      </Routes>
    </div>
  );
};

export default App;